// client/src/components/upload/ColumnMapper.jsx
import React, { useState, useEffect } from 'react';
import { Save, ArrowLeft, ArrowRight, FileText } from 'lucide-react';

const ColumnMapper = ({ 
  headers, 
  previewData, 
  mailingListId, 
  fileInfo,  // Changed from selectedFile to fileInfo
  onMappingComplete, 
  onBack 
}) => {
  const [mappings, setMappings] = useState({});
  const [showSaveTemplate, setShowSaveTemplate] = useState(false);
  const [templateName, setTemplateName] = useState('');

  // Define our standard fields with categories
  const standardFields = {
    'Mailing List Fields': [
      'mailing_list_id',
      'first_name',
      'middle_name',
      'last_name',
      'full_name',
      'company_name',
      'email',
      'address',
      'address_2',
      'city',
      'state',
      'zip',
      'city_state_zip'
    ],
    'USPS Fields': [
      'no',
      'ase',
      'oel',
      'presorttrayid',
      'presortdate',
      'imbno',
      'encodedimbno'
    ],
    'Custom Fields': [
      'custom_1',
      'custom_2',
      'custom_3',
      'custom_4',
      'custom_5',
      'custom_6',
      'custom_7',
      'custom_8',
      'custom_9',
      'custom_10'
    ]
  };

  // Get flat list of all fields
  const allFields = Object.values(standardFields).flat();

  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleMappingChange = (standardField, fileHeader) => {
    setMappings(prev => ({
      ...prev,
      [standardField]: fileHeader
    }));
  };

  // Get preview values for a header
  const getPreviewValues = (header) => {
    return previewData
      .slice(0, 3)
      .map(row => row[header])
      .filter(val => val != null && val !== '');
  };

  const isFieldMapped = (standardField) => {
    return mappings[standardField] !== undefined && mappings[standardField] !== '';
  };

  return (
    <div className="mt-6 bg-white rounded-lg shadow border border-gray-200">
      <div className="p-6">
        {/* File Info Header - Now with null checks */}
        {fileInfo && (
          <div className="mb-6 bg-gray-50 rounded-lg p-4 flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <FileText className="w-6 h-6 text-blue-500" />
              <div>
                <h3 className="text-lg font-medium">{fileInfo.name}</h3>
                <p className="text-sm text-gray-600">
                  Uploaded: {formatDate(fileInfo.created_at)}
                </p>
              </div>
            </div>
            <div className="text-right">
              <span className="text-lg font-medium">{fileInfo.row_count || 0}</span>
              <p className="text-sm text-gray-600">Records</p>
            </div>
          </div>
        )}

        <h2 className="text-xl font-semibold mb-4">Column Mapping</h2>

        {/* Rest of the component remains the same */}
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="text-left p-2 bg-gray-50 border">Required Field</th>
                <th className="text-left p-2 bg-gray-50 border">File Column</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(standardFields).map(([category, fields]) => (
                <React.Fragment key={category}>
                  <tr>
                    <td 
                      colSpan="2" 
                      className="bg-gray-100 p-2 font-medium"
                    >
                      {category}
                    </td>
                  </tr>
                  {fields.map(field => (
                    <tr 
                      key={field}
                      className={`border-t ${
                        isFieldMapped(field) ? 'bg-green-50' : ''
                      }`}
                    >
                      <td className="p-2 border font-medium">
                        {field.replace(/_/g, ' ').toUpperCase()}
                      </td>
                      <td className="p-2 border">
                        <select
                          value={mappings[field] || ''}
                          onChange={(e) => handleMappingChange(field, e.target.value)}
                          className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="">Select a column...</option>
                          {headers.map(header => {
                            const preview = getPreviewValues(header);
                            return (
                              <option key={header} value={header}>
                                {header} {preview.length > 0 ? `(e.g., ${preview.join(', ')})` : ''}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        {/* Template saving section */}
        {showSaveTemplate && (
          <div className="mt-4">
            <input
              type="text"
              placeholder="Template name"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              className="w-full p-2 border rounded mb-2"
            />
            <button
              onClick={() => {
                // Handle template saving
                setShowSaveTemplate(false);
                setTemplateName('');
              }}
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Save className="w-4 h-4 mr-2" />
              Save Template
            </button>
          </div>
        )}

        {/* Navigation buttons */}
        <div className="mt-6 flex justify-between">
          <button
            onClick={onBack}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back
          </button>
          <div className="space-x-2">
            <button
              onClick={() => setShowSaveTemplate(!showSaveTemplate)}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50"
            >
              <Save className="w-4 h-4 mr-2" />
              Save as Template
            </button>
            <button
              onClick={() => onMappingComplete(mappings)}
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Continue
              <ArrowRight className="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnMapper;