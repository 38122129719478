import React from 'react';

const steps = [
  { id: 'upload', label: 'Upload List' },
  { id: 'mapping', label: 'Map Columns' },
  { id: 'qr', label: 'QR Configuration' },
  { id: 'template', label: 'Template Selection' }
];

export default function Stepper({ currentStep }) {
  return (
    <nav className="mb-8">
      <ol className="flex items-center w-full">
        {steps.map((step, index) => (
          <li 
            key={step.id}
            className={`flex items-center ${
              index !== steps.length - 1 ? 'w-full' : ''
            }`}
          >
            <div className="flex items-center">
              <span className={`
                w-8 h-8 flex items-center justify-center rounded-full
                ${currentStep === step.id 
                  ? 'bg-blue-600 text-white' 
                  : index < steps.findIndex(s => s.id === currentStep)
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-200 text-gray-600'
                }
              `}>
                {index + 1}
              </span>
              <span className="ml-4 text-sm font-medium text-gray-900">
                {step.label}
              </span>
            </div>
            
            {index !== steps.length - 1 && (
              <div className="flex-1 mx-4">
                <div className={`h-0.5 ${
                  index < steps.findIndex(s => s.id === currentStep)
                    ? 'bg-green-500'
                    : 'bg-gray-200'
                }`} />
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}