// client/src/components/upload/FileUploadComponent.jsx

import React, { useState, useEffect } from 'react';
import { Upload, FileText, Trash2 } from 'lucide-react';
import ColumnMapper from './ColumnMapper';
import DataPreview from './DataPreview';
import Stepper from '../layout/Stepper';

const FileUploadComponent = ({ user }) => {
  // Original state from working implementation
  const [files, setFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  
  // New mapping state
  const [currentStep, setCurrentStep] = useState('upload');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [mappings, setMappings] = useState(null);
  const [mappedData, setMappedData] = useState(null);

  // Original useEffect for fetching files
  useEffect(() => {
    fetchFiles();
  }, []);

  // Original file fetching function - preserved exactly
  const fetchFiles = async () => {
    try {
      console.log('Fetching files...');
      const response = await fetch('/api/lists', {
        credentials: 'include'
      });
      const data = await response.json();
      console.log('Files fetched:', data);
      setFiles(data);
    } catch (err) {
      console.error('Error fetching files:', err);
      setError('Failed to load files');
    }
  };

  // Original upload handler with added mapping integration
  const handleUpload = async (file) => {
    setIsUploading(true);
    setUploadProgress(0);
    setError(null);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('/api/lists/upload', {
        method: 'POST',
        credentials: 'include',
        body: formData
      });
      
      const data = await response.json();
      
      if (data.error) {
        throw new Error(data.error);
      }

      await fetchFiles();
      console.log('Upload response:', data);
      
      // Only proceed to mapping if we have preview data
      if (data.preview) {
        setSelectedFile(data);
        setFileData(data.preview);
        setCurrentStep('mapping');
      }
    } catch (err) {
      console.error('Upload error:', err);
      setError('Upload failed: ' + err.message);
    } finally {
      setIsUploading(false);
    }
  };

  // Original delete handler - preserved exactly
  const handleDelete = async (fileId) => {
    try {
      await fetch(`/api/lists/${fileId}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      await fetchFiles();
      setShowDeleteConfirm(null);
    } catch (err) {
      console.error('Delete error:', err);
      setError('Failed to delete file');
    }
  };

  // New handler for selecting existing files
  const handleFileSelect = async (existingFile) => {
    try {
      setError(null);
      setSelectedFile(existingFile);
      console.log('Loading file:', existingFile.id);

      const response = await fetch(`/api/lists/${existingFile.id}`, {
        credentials: 'include'
      });
      
      const data = await response.json();
      console.log('File data received:', data);
      
      if (data.error) {
        throw new Error(data.error);
      }

      if (data.preview) {
        setFileData(data.preview);
        setCurrentStep('mapping');
      } else {
        throw new Error('No preview data available');
      }
    } catch (err) {
      console.error('Error loading file:', err);
      setError('Failed to load file: ' + err.message);
      setSelectedFile(null);
      setFileData(null);
      setCurrentStep('upload');
    }
  };

  // New handler for mapping completion
  const handleMappingComplete = (mappings) => {
    if (!fileData || !fileData.rows) {
      setError('No file data available');
      return;
    }

    try {
      setMappings(mappings);
      
      const transformed = fileData.rows.map(row => {
        const mapped = {};
        Object.entries(mappings).forEach(([header, field]) => {
          if (field) {
            mapped[field] = row[header];
          }
        });
        return mapped;
      });
      
      setMappedData(transformed);
      setCurrentStep('preview');
    } catch (err) {
      console.error('Error processing mappings:', err);
      setError('Failed to process mapped data');
    }
  };

  // Original date formatting function - preserved exactly
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Enhanced render function with new steps
  const renderStep = () => {
    switch (currentStep) {
      case 'mapping':
  if (!fileData || !fileData.headers || !fileData.rows) {
    setCurrentStep('upload');
    return null;
  }
  return (
    <ColumnMapper
      headers={fileData.headers}
      previewData={fileData.rows}
      mailingListId={selectedFile?.id}
      fileInfo={selectedFile}  // Pass the entire file info object
      onMappingComplete={handleMappingComplete}
      onBack={() => {
        setCurrentStep('upload');
        setSelectedFile(null);
        setFileData(null);
      }}
    />
  );

      case 'preview':
        if (!mappedData) {
          setCurrentStep('mapping');
          return null;
        }
        return (
          <DataPreview
            mappedData={mappedData}
            onBack={() => setCurrentStep('mapping')}
            onContinue={() => setCurrentStep('qr')}
          />
        );

      case 'upload':
      default:
        // Original upload view with preserved styling
        return (
          <div className="max-w-7xl mx-auto p-6">
            {error && (
              <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded">
                {error}
              </div>
            )}

            {/* Existing Files Section - Preserved Exactly */}
            {files.length > 0 && (
              <div className="mb-8">
                <h2 className="text-xl font-semibold mb-4">
                  Your Files ({files.length})
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {files.map((file) => (
                    <div
                      key={file.id}
                      className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 cursor-pointer hover:border-blue-500 transition-colors"
                      onClick={() => handleFileSelect(file)}
                    >
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center space-x-2">
                          <FileText className="w-5 h-5 text-blue-500" />
                          <span className="font-medium truncate">{file.name}</span>
                        </div>
                        {showDeleteConfirm === file.id ? (
                          <div className="flex items-center space-x-2">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(file.id);
                              }}
                              className="text-xs text-red-600 hover:text-red-800"
                            >
                              Confirm
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowDeleteConfirm(null);
                              }}
                              className="text-xs text-gray-500 hover:text-gray-700"
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowDeleteConfirm(file.id);
                            }}
                            className="p-1 hover:bg-gray-100 rounded"
                          >
                            <Trash2 className="w-4 h-4 text-red-500" />
                          </button>
                        )}
                      </div>
                      <div className="text-sm text-gray-500">
                        <div>{file.row_count || 0} records</div>
                        <div>{formatDate(file.created_at)}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Upload Section - Preserved Exactly */}
            <div className="bg-white rounded-lg shadow-sm border border-gray-200">
              <div className="p-6">
                <div
                  className={`border-2 border-dashed rounded-lg p-8 text-center ${
                    isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
                  }`}
                  onDragOver={(e) => {
                    e.preventDefault();
                    setIsDragging(true);
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    setIsDragging(false);
                  }}
                  onDrop={(e) => {
                    e.preventDefault();
                    setIsDragging(false);
                    const file = e.dataTransfer.files[0];
                    if (file) handleUpload(file);
                  }}
                >
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <p className="mt-4 text-lg font-medium text-gray-700">
                    Drag and drop your CSV or Excel file here
                  </p>
                  <p className="text-sm text-gray-500 mt-2">or</p>
                  <label className="mt-4 inline-block">
                    <input
                      type="file"
                      className="hidden"
                      accept=".csv,.xls,.xlsx"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) handleUpload(file);
                      }}
                    />
                    <span className="cursor-pointer inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                      Browse Files
                    </span>
                  </label>
                </div>

                {isUploading && (
                  <div className="mt-4">
                    <div className="w-full h-2 bg-gray-200 rounded-full">
                      <div
                        className="h-full bg-blue-600 rounded-full transition-all duration-300"
                        style={{ width: `${uploadProgress}%` }}
                      />
                    </div>
                    <p className="text-sm text-gray-600 mt-2 text-center">
                      Uploading... {uploadProgress}%
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
    }
  };

  // Main component render with step navigation
  return (
    <div className="min-h-screen bg-gray-100">
      <Stepper currentStep={currentStep} />
      {renderStep()}
    </div>
  );
};

export default FileUploadComponent;